import React, { useState } from 'react';

export default function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className="bg-[#222222] text-white">
      <div className="max-w-7xl mx-auto px-4 flex items-center">
        {/* Logo */}
        <div className="flex-shrink-0 py-4 md:py-6">
          <a href="https://www.coursecreator360.com/">
            <img
              src="https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/c2DjRsOo4e13Od6ZTU6S/media/636547bb724368117f1cc776.png"
              alt="Course Creator Logo"
              className="h-10 w-auto md:h-20" // Responsive height: 10 on mobile, 20 on larger screens
            />
          </a>
        </div>

        {/* Spacer to push navigation links to the right */}
        <div className="flex-1"></div>

        {/* Desktop Navigation Links */}
        <div className="hidden md:flex space-x-8">
          <a
            href="https://www.coursecreator360.com"
            className="text-white hover:text-gray-300"
          >
            Home
          </a>
          <a
            href="https://app.coursecreator360.com"
            className="text-white hover:text-gray-300"
          >
            Login
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-white hover:text-gray-300 focus:outline-none"
          >
            {/* Hamburger Icon */}
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden">
          <div className="px-4 pb-4 space-y-2">
            <a
              href="https://www.coursecreator360.com"
              className="block text-white hover:text-gray-300"
            >
              Home
            </a>
            <a
              href="https://app.coursecreator360.com"
              className="block text-white hover:text-gray-300"
            >
              Login
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}